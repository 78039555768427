<template>
  <div>
    <div class="content-wrap flex" style="margin-bottom:20px">
      <div class="status">
        <div class="name">即将逾期(待处理)</div>
        <countTo class="num" :startVal='0' :endVal='statusNum.jijiangyuqi' :duration='3000'></countTo>
      </div>
      <div class="status">
        <div class="name">已逾期(待处理)</div>
        <countTo class="num" :startVal='0' :endVal='statusNum.yiyuqi' :duration='3000'></countTo>
      </div>
      <div class="status">
        <div class="name">待分配</div>
        <countTo class="num" :startVal='0' :endVal='statusNum.daifenpei' :duration='3000'></countTo>
      </div>
      <div class="status">
        <div class="name">待处理</div>
        <countTo class="num" :startVal='0' :endVal='statusNum.daichuli' :duration='3000'></countTo>
      </div>
      <div class="status">
        <div class="name">处理中</div>
        <countTo class="num" :startVal='0' :endVal='statusNum.chulizhong' :duration='3000'></countTo>
      </div>
      <div class="status">
        <div class="name">待评价</div>
        <countTo class="num" :startVal='0' :endVal='statusNum.daipingjia' :duration='3000'></countTo>
      </div>
      <div class="status">
        <div class="name">已评价</div>
        <countTo class="num" :startVal='0' :endVal='statusNum.yipingjia' :duration='3000'></countTo>
      </div>
      <div class="status">
        <div class="name">已取消</div>
        <countTo class="num" :startVal='0' :endVal='statusNum.quxiao' :duration='3000'></countTo>
      </div>
    </div>
    <!-- 宽带报修 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
        <el-form-item>
            <el-select clearable filterable  placeholder="请选择状态" @change="resetPageAndRefresh();numRefresh()" v-model="queryParams.status">
                <el-option value="">全部</el-option>
                <el-option value="6" label="即将逾期">即将逾期</el-option>
                <el-option value="7" label="已逾期">已逾期</el-option>
                <el-option value="1" label="待分配">待分配</el-option>
                <el-option value="2" label="待处理">待处理</el-option>
                <el-option value="3" label="已评价">处理中</el-option>
                <el-option value="4" label="待评价">待评价</el-option>
                <el-option value="5" label="已评价">已评价</el-option>
                <el-option value="-1" label="已取消">已取消</el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'startTime','endTime','queryParams');numRefresh()" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="queryParams.handlerId" placeholder="维修人员名单" v-if="orderOption" @change="refresh">
            <el-option v-for="item in orderOption" :label="item.extend.username" :value="item.userId" :key="item.id"></el-option>
          </el-select>
        </el-form-item>    
            
        <el-form-item>
            <el-select clearable filterable  placeholder="请选择学校" @change="resetPageAndRefresh();numRefresh()" v-model="queryParams.xxid">
                <el-option value="">全部</el-option>
                <el-option value="1" label="安徽科技学院凤阳">安徽科技学院凤阳</el-option>
                <!-- <el-option value="2" label="蚌埠学院">蚌埠学院</el-option> -->
                <el-option value="3" label="安徽科技学院龙湖">安徽科技学院龙湖</el-option>  
                <el-option value="4" label="滨湖学院">滨湖学院</el-option>             
            </el-select>
        </el-form-item>

        <el-form-item>
            <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh();numRefresh()">搜索</el-button>
        </el-form-item>

        <!-- <el-form-item label="刷新频率：" style="float:right">
            <el-select clearable filterable  placeholder="请选择刷新频率" @change="resetInterval()" v-model="frequent">
                <el-option value="600" label="600s"></el-option>
                <el-option value="30" label="30s"></el-option>
                <el-option value="60" label="60s"></el-option>
                <el-option value="90" label="90s"></el-option>                
            </el-select>
        </el-form-item> -->

        <el-form-item style="float:right">
          <div class="btn-wrap cfx">
            <el-button type="success" size="small" icon="el-icon-plus" @click="toAdd" style="margin-right:10px">添加</el-button>
            <el-link :href="BaseUrl + `operate/repair/noLogin/exportDetail?start=${queryParams.startTime}&end=${queryParams.endTime}&handlerId=${queryParams.handlerId}&schoolId=${queryParams.xxid}`" class="button_download">
            <el-button type="warning" size="small" icon="el-icon-position">导出</el-button>
          </el-link>
          </div>
        </el-form-item>

    </el-form>
    <div class="content-wrap">
      <paged-table
        :data="networkList"
        :total="networkTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh"
      > 
        <el-table-column sortable="custom" prop="extend.schoolName" show-overflow-tooltip label="学校" width="150">
        </el-table-column>
        <el-table-column sortable="custom" show-overflow-tooltip label="报修人" width="80">
          <template slot-scope="scope" >
            <span v-if="scope.row.extend.userName">{{scope.row.extend.userName}}</span>
            <span v-else>{{scope.row.qq}}</span>            
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="phone" show-overflow-tooltip label="联系电话" width="100"></el-table-column>
        <el-table-column sortable="custom" prop="room" show-overflow-tooltip label="宿舍号" width="100"></el-table-column>
        <el-table-column sortable="custom" prop="account" show-overflow-tooltip label="学号" width="100"></el-table-column>
        <el-table-column sortable="custom" prop="content" show-overflow-tooltip label="内容" width="200"></el-table-column>
        <el-table-column sortable="custom" prop="extend.handlerName" show-overflow-tooltip label="维修人" width="80"></el-table-column>
        <el-table-column label="状态" width="80" >
          <template slot-scope="scope" >
            <span v-if="scope.row.status === 1" label="待分配">待分配</span>
            <span v-if="scope.row.status === 2" label="待处理">待处理</span>
            <span v-if="scope.row.status === 3" label="已评价">处理中</span>
            <span v-if="scope.row.status === 4" label="待评价">待评价</span>
            <span v-if="scope.row.status === 5" label="已评价">已评价</span>
            <span v-if="scope.row.status === -1" label="已取消">已取消</span>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="addTime" show-overflow-tooltip label="报修时间" width="150"></el-table-column>
        <!-- <el-table-column sortable="custom" prop="paiTime" show-overflow-tooltip label="派单时间" width="150"></el-table-column> -->
        <el-table-column sortable="custom" prop="resTime" show-overflow-tooltip label="完成时间" width="150"></el-table-column>
        <!-- <el-table-column sortable="custom" prop="handleTime" show-overflow-tooltip label="维修用时" width="100"></el-table-column> -->
        <el-table-column sortable="custom" prop="score" show-overflow-tooltip label="评价得分" width="100"></el-table-column>
        <el-table-column sortable="custom" prop="visitContent" show-overflow-tooltip label="回访记录" width="200"></el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailsInfo(scope.row.id)" icon="el-icon-search" title="详情"></el-button>
            <el-button size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
            <el-button v-if="scope.row.statusName == '待分配'" size="mini" type="warning" @click="designate(scope.row)" icon="el-icon-right" title="派单"></el-button>
            <el-button v-if="scope.row.statusName == '待处理' || scope.row.statusName == '处理中'" 
              size="mini" type="success" @click="complete(scope.row)" icon="el-icon-check" title="完成">
            </el-button>
            
            <el-button size="mini" type="warning" @click="toVisit(scope.row)" icon="el-icon-phone" title="回访">
            </el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 详情 -->
    <el-drawer
      title="宽带报修详情"
      :visible.sync="bVisible"
      size="700px"
      >
      <div class="allocationdetail">
        <div class="d-head">
          <div class="headtit">
            <!-- <div class="area">
              <p class="con-txt">{{ details.addTime }}</p>
              <p class="mission">报修时间</p>
            </div> -->
            <div class="area fr" v-if="!!details.res || details.res==0">
              <p class="con-txt tinctblue" v-if="details.res==0">待维修</p>
              <p class="con-txt tinctgreen" v-else-if="details.res==1">已完成</p>
              <p class="con-txt tinctred" v-else-if="details.res==2">无法维修</p>
              <p class="con-txt tinctgreen" v-else-if="details.res==3">无需维修</p>
              <p class="mission">状态</p>
            </div>
            <div class="area fr" v-else>
              <p class="con-txt tinctblue" v-if="details.status==1">待分配</p>
              <p class="con-txt tinctred" v-else-if="details.status==2">待处理</p>
              <p class="con-txt tinctblue" v-else-if="details.status==3">处理中</p>
              <p class="con-txt" style="color:#222222" v-else-if="details.status>=4">处理完成</p>
              <!-- <p class="con-txt tinctgreen" v-else-if="details.status==5">已评价</p> -->
              <p class="con-txt" style="color: #8E8E8E" v-else-if="details.status==-1">已取消</p>
              <p class="mission">状态</p>
            </div>
            <div class="area" v-if="details.status <= 3">
              <p class="con-txt">{{ details.appointTime }}</p>
              <p class="mission">预约时间</p>
            </div>
            <div class="area" v-if="details.status > 3">
              <p class="con-txt">{{ details.useTime }}</p>
              <p class="mission">维修用时</p>
            </div>
            <div style="position:absolute;top:20px;right:120px" v-if="details.status==2">
              <span v-if="(new Date().getTime() - new Date(details.appointTime).getTime()) / 1000 > -1800 && (new Date().getTime() - new Date(details.appointTime).getTime()) / 1000 < 0" style="font-size:14px;color:#fff;background-color:#FF9240;padding:3px 6px;border-radius:2px">即将逾期请及时处理</span>
              <span v-if="(new Date().getTime() - new Date(details.appointTime).getTime()) / 1000 < 1800 && (new Date().getTime() - new Date(details.appointTime).getTime()) / 1000 > 0" style="font-size:14px;color:#fff;background-color:#ff5353;padding:3px 6px;border-radius:2px">已逾期请及时处理</span>
            </div>
            <div style="position:absolute;top:20px;right:120px" v-if="details.status>=4">
              <span v-if="(new Date(details.beginTime).getTime() - new Date(details.appointTime).getTime()) / 1000 > 0" style="font-size:14px;color:#fff;background-color:#ff5353;padding:3px 6px;border-radius:2px">逾期处理</span>
            </div>
          </div>
          
          <div class="headcon">
            <p class="contit">报修人</p>
            <el-image :src="details.headImg" class="pig" alt=""></el-image>
            <div class="area" v-if="details.extend">
              <p class="con-txt">
                {{ details.extend.userName? details.extend.userName: '---' }}
                <img :src="details.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px">
              </p>
              <!-- <p class="mission">{{ details.extend.schoolName? details.extend.schoolName: '---' }} {{ details.fade? '| '+details.fade: '' }}</p> -->
              <p class="mission">{{ details.fade? details.fade: '---' }}</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ details.phone }}</p>
              <p class="mission">手机号码</p>
            </div>
            <!-- <div class="area" v-if="details.extend">
              <p class="con-txt">{{ details.extend.schoolName }}</p>
              <p class="mission">学校名称</p>
            </div> -->
          </div>
        </div>

        <div class="d-bodyFirst">
          <div class="repair">
            <p class="contit">报修内容</p>
            <div class="repair-con">
              <div class="conitem">
                <div class="conlable">报修时间</div>
                <div class="contxt">{{ details.addTime }}</div>
              </div>
              <div class="conitem" v-if="details.status >=3">
                <div class="conlable">预约时间</div>
                <div class="contxt">{{ details.appointTime }}</div>
              </div>
              <div class="conitem">
                <div class="conlable">故障设备</div>
                <div class="contxt">{{ details.device }}</div>
              </div>
              <div class="conitem">
                <div class="conlable">联系地址</div>
                <div class="contxt">{{ details.room }}</div>
              </div>
              <div class="conitem">
                <div class="conlable">故障描述</div>
                <p>{{ details.content }}</p>
                <p>
                  <viewer :images="infoPicList">
                    <el-image v-for="(item,index) in infoPicList"  :src="item" :key="index"></el-image>
                  </viewer>
                </p>
              </div>
            </div>
          </div>
          <div class="repair" v-if="details.status==1" >
            <p class="contit">分配维修人员</p>
            <el-button type="success" style="width:120px;margin:20px auto;display:block" @click="designate(details)" title="派单">派单</el-button>
          </div>
          <!-- 维修员 -->
          <div class="taskcon" v-if="details.handlerId != null && details.extend != null">
            <div class="headcon">
              <p class="contit">维修员</p>
              <el-image :src="details.handler.extend.headImg" class="pig" alt=""></el-image>
                <div class="area">
                  <p class="con-txt">
                    {{ details.handler.extend.userName?details.handler.extend.userName:'---' }}
                    <img :src="details.handler.extend.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px">
                  </p>
                  <p class="mission">工号：{{ details.handler.account }}</p>
                </div>
                <div class="area">
                  <p class="con-txt">{{ details.handler.extend.mobile? details.handler.extend.mobile: '---' }}</p>
                  <p class="mission">手机号码</p>
                </div>
                <!-- <div class="area">
                  <p class="con-txt">{{ details.extend.schoolName? details.extend.schoolName: '---' }}</p>
                  <p class="mission">学校名称</p>
                </div> -->
                <div class="conitem" v-if="details.paiTime">
                  <div class="conlable">接单/分配时间</div>
                  <div class="contxt">{{ details.paiTime }}</div>
                </div>
                <div class="conitem" v-if="details.beginTime">
                  <div class="conlable">开始处理时间</div>
                  <div class="contxt">{{ details.beginTime }}</div>
                </div>
            </div>

            <div class="repair" v-if="details.status>=4">
              <div class="repair-con">
                <!-- <div class="conitem">
                  <div class="conlable">处理时间</div>
                  <div class="contxt">{{ details.resTime }}</div>
                </div> -->
                <div class="conitem">
                  <div class="conlable">处理结果</div>
                  <div class="contxt">{{ details.res == 1 ? '已完成' : details.res == 2 ? '无法维修' : '无需维修' }}</div>
                </div>
                <div class="conitem">
                  <div class="conlable">损坏原因</div>
                  <div class="contxt">{{ details.badReason == 1 ? '人为损坏' : details.badReason == 2 ? '设备老化' : '自然损坏' }}</div>
                </div>
                <div class="conitem">
                  <div class="conlable">材料消耗</div>
                  <div class="contxt">{{ details.resMaterial ? details.resMaterial : '无' }}</div>
                </div>
                <div class="conitem">
                  <div class="conlable">工单总结</div>
                  <p>{{ details.resSummary ? details.resSummary : '无' }}</p>
                  <p v-if="imgListB && imgListB.length">
                    <viewer :images="imgListB">
                      <el-image v-for="(item, index) in imgListB" :key="index" :src="item"></el-image>
                    </viewer>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- 报修人评价 -->
          <div class="taskcon" v-if="details.evaluate">
            <div class="repair">
              <p class="contit">报修人评价</p>
              <div class="conitem" v-if="!details.evaluate">
                <div class="conlable">暂无评价</div>
              </div>

              <div class="repair-con" v-else>
                <div class="conitem">
                  <div class="conlable">提交时间</div>
                  <div class="contxt">{{ details.evaluate.time }}</div>
                </div>
                <div class="conitem">
                  <div class="conlable">评分</div>
                  <div class="contxt" v-if="details.evaluate.score"> 
                    <el-rate  v-model="details.evaluate.score" disabled-void-color="#fff" disabled show-score text-color="#ff9900" score-template="{value}"></el-rate>
                </div>
                </div>
                <div class="conitem" v-if="details.evaluate.content">
                  <div class="conlable">评价内容</div>
                  <div class="contxt">{{ details.evaluate.content }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </el-drawer>

    <!-- 派单 -->
    <el-dialog
      title="派单"
      :visible.sync="orderVisible"
      width="300px"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="80px"
        ref="orderUserFileds"
        :model="orderUser"
        size="small"
      >
        <el-form-item label="维修人员" prop="handleId">
          <el-select clearable filterable v-model="orderUser.id">
            <el-option v-for="item in orderOption" :label="item.extend.username" :value="item.userId" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="orderVisible = false">取消</el-button>
          <el-button size="small" type="primary" :loading="bLoading" @click="commit">立即提交</el-button>
        </div>
    </el-dialog>

    <!-- 完成 -->
    <el-dialog
      title="完成"
      :visible.sync="completeVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="80px"
        ref="completeFormFileds"
        :rules="completeRule"
        :model="completeForm"
        size="small"
      >
        <el-form-item label="结果：" prop="resSummary">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="completeForm.resSummary">
            </el-input>
        </el-form-item>
      </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="completeVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="completeCommit">立即提交</el-button>
        </div>
    </el-dialog>

    <!-- 添加回访记录 -->
    <el-dialog
      title="添加回访记录"
      :visible.sync="visitVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="100px"
        ref="visitFormFileds"
        :rules="visitRule"
        :model="visitForm"
        size="small"
      >
        <el-form-item label="回访记录：" prop="content">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="visitForm.content">
            </el-input>
        </el-form-item>
      </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="visitVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="visitCommit">立即提交</el-button>
        </div>
    </el-dialog>

    <!-- 新增 -->
    <el-dialog title="新增工单" :visible.sync="addVisible" width="500px" :close-on-click-modal="false" @close="addVisible=false">
      <el-form label-width="110px" :model="form" :rules="formRules" ref="addForm" size="small">
        <el-form-item label="所属学校：" prop="schoolId">
          <el-select clearable filterable v-model="form.schoolId">
            <el-option label="安徽科技学院凤阳校区" :value="1"></el-option>
            <el-option label="蚌埠学院" :value="2"></el-option>
            <el-option label="安徽科技学院龙湖校区" :value="3"></el-option>
            <el-option label="滨湖学院" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="故障设备：" prop="device">
          <el-input v-model="form.device" placeholder="请输入故障设备" clearable></el-input>
        </el-form-item>
        <el-form-item label="报修内容：" prop="content" >
          <el-input v-model="form.content" placeholder="请输入报修内容" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号" clearable></el-input>
        </el-form-item>
        <el-form-item label="宿舍号：" prop="room">
          <el-input v-model="form.room" placeholder="请输入宿舍号" clearable></el-input>
        </el-form-item>
        <el-form-item label="学号：" prop="account">
          <el-input v-model="form.account" placeholder="请输入学号" clearable></el-input>
        </el-form-item>
        <el-form-item label="报修人：" prop="qq">
          <el-input v-model="form.qq" placeholder="请输入报修人" clearable></el-input>
        </el-form-item>           
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="addVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitAdd">保存</el-button>
      </div>
    </el-dialog>


  </div>
</template>


<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import countTo from 'vue-count-to'
import datePicker from "@/mixins/datePicker";
import { log, mergeObject,dateFormat } from '@/common/utils'
import { BaseUrl } from "@/common/config";
export default {
  name:'broadbandService',
  components: {
    PagedTable,
    countTo
  },
  mixins: [pageMixin,datePicker],
  data() {
    return {
      BaseUrl,
      frequent: '6000',
      timer: null,
      queryParams:{
        type: 1,
        xxid: '',
        startTime: '',
        endTime:'',
        handlerId:''
      },
      orderVisible: false,
      completeVisible: false,
      visitVisible: false,
      details: {
        extend: {
        schoolName: '',
        userName: ''
        },
        id:'',
        type: '',
        device: '',
        content: '',
        phone: '',
        wx: '',
        qq: '',
        appointTime: '',
        pics: '',
        room: '',
        status: '',
        userId: '',
        schoolId: '',
        addTime: '',
        cancelReason: null,
        handlerId: null,
        paiTime: null,
        res: null,
        resTime: null,
        resMaterial: null,
        resPics: null,
        resSummary: null,
        statusName: '',
        resName: null,
        headImg: '',
        sex: '',
        handler: null,
        evaluate: null,
        fade: '',
        major: ''
        },
      orderUser:{
        id:"",
      },
      completeForm:{
        id:"",
        resSummary: ''
      },
      visitForm:{
        repairId:"",
        userId:"",
        handlerId:"",
        content: ''
      },
      statusNum:{},
      handleOrderdetail: {
        schoolId: null,
      },
      // 报修单id
      id:null,
      orderOption: [],
      pageApi: "repairList",
      deleteApi: "repairDelete",
      loadApi:'repairInfo',
      insertApi: "repairAdd",
      // editorApi: "commonRoleEdit",  
      addVisible: false,
      form:{
        schoolId: '',
        device: '',
        content: '',
        phone: '',
        room:'',
        account: '',
        qq:'', //后台添加时用qq充当报修人姓名
      },    
      formRules: {
        schoolId: [{ required: true, message: "请选择学校", trigger: "change" }],
        device: [{ required: true, message: "请输入故障设备", trigger: "blur" }],
        content: [{ required: true, message: "请输入报修内容", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        room: [{ required: true, message: "请输入宿舍号", trigger: "blur" }],
        account: [{ required: true, message: "请输入学号", trigger: "blur" }],
        qq: [{ required: true, message: "请输入报修人", trigger: "blur" }],
      },
      completeRule: {      
        resSummary: [{ required: true, message: "请输入结果", trigger: "blur" }],
      },
      visitRule: {      
        content: [{ required: true, message: "请输入回访内容", trigger: "blur" }],
      },

    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("broadbandService", ['networkList','networkTotal']),
    infoPicList() {
      return this.details.pics ? this.details.pics.split(',') : []
    },
    imgListB() {
      return this.details.resPics ? this.details.resPics.split(',') : []
    }   
  },
  created(){
    console.log("netrepair===created")
    // 加载一个定时器
    this.resetInterval()    
  },
  beforeDestory(){
    console.log("netrepair===beforeDestory")
    this.timer && clearInterval(this.timer)  
  },
  methods: {
    ...mapActions("broadbandService",['repairList','repairEdit','repairAdd','repairLoad','repairDelete','repairAssign','repairVisit','getAppRoleUsers','repairInfo']),
    ...mapActions("realService",['getStatusNum']),
    ...mapActions("fuseOrder",["getAppRoleUsers" ]),    

    /** 添加弹框 */
    async toAdd(){
      this.addVisible = true
    },
    /** 添加工单 */
    async submitAdd() {     
      this.$refs['addForm'].validate((valid) => {
          if (valid) {
            try{
              let extra = {type:1, status:1, addTime:dateFormat(new Date(),'yyyy-MM-dd hh:mm:ss')}
              let params = {...this.form, ...extra}
              console.log('params',params)
              this.repairAdd({...this.form, ...extra})
              this.$message({
                type: 'success',
                message: '添加成功'
              });
            }catch (error){
              this.$message.error("添加失败")
            }finally{
              this.addVisible = false
              this.afterHandle()
              this.refresh()
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
    },

    // 后续操作
    afterHandle(){
      delete this.form.id
      delete this.form.schoolId
      delete this.form.device
      delete this.form.phone
      delete this.form.content
    },

    // 定时刷新
    async resetInterval(){
      // let seconds = this.frequent || 600;
      let seconds = 600;
      console.log("netrepair===创建定时器前======",this.timer)
      let that = this
      that.timer && clearInterval(that.timer)
      that.timer = setInterval(function(){
        console.log("netrepair===resetInterval=======",seconds)
        that.refresh();
        that.numRefresh()
      }, seconds * 1000)    
      console.log("netrepair===创建定时器后======",this.timer)  
    },
    // 下拉
    async handleOrder() {
      let res = await this.getAppRoleUsers({roleType: 1})
      this.orderOption = res
    },
    // 派单详情
    async detailsInfo(id) {
      this.bVisible = true
      this.details = await this.repairInfo({id})
    },

    // 完成工单确认框
    async complete(row) {
      console.log('报修单id====',row.id)
      this.completeForm.id = row.id
      this.completeVisible = true
      // this.$confirm('确定完成该工单吗?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(async () => {
      //     try{
      //       let res = await that.repairEdit({id:row.id, status:4, res:1, resTime:dateFormat(new Date(),'yyyy-MM-dd hh:mm:ss')})
      //       console.log('res==========',res)
      //       this.$message({
      //         type: 'success',
      //         message: '操作成功'
      //       });
      //     }catch (error){
      //       this.$message.error("操作失败")
      //     }
      //   }).catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消删除'
      //     });          
      //   });

    },
    // 完成工单确认框
    async toVisit(row) {
      console.log('报修单id====',row.id)
      this.visitForm.repairId = row.id
      this.visitForm.userId = row.userId
      this.visitForm.handlerId = row.handlerId
      this.visitForm.content = ""
      this.visitVisible = true
    },

    // 打开派单功能弹窗
    async designate(row) {
      console.log(row)
      console.log(row.schoolId)
      this.orderVisible = true
      this.id =  row.id
      let obj = {
        schoolId: row.schoolId,
        appRoleId: 1
      }
    },
    changeUser() {
    },
    async commit() {
      this.orderVisible = false
      let obj = {
        handlerId: this.orderUser.id,
        id: this.id
      }
      const res = await this.repairAssign(obj)
      console.log(res)
      this.$message.success('分配成功')
      this.numRefresh()
      this.refresh()
    },

    //完成工单提交
    async completeCommit() {
      this.completeVisible = false
      
      let obj = {
        id: this.completeForm.id,
        resSummary: this.completeForm.resSummary,
        status: 4,
        res: 1,
        resTime: dateFormat(new Date(),'yyyy-MM-dd hh:mm:ss')
      }
      let res = await this.repairEdit(obj)
      console.log(res)
      this.$message.success('提交成功')
      this.numRefresh()
      this.refresh()
    },

    //回访记录提交
    async visitCommit() {
      this.visitVisible = false      
      let obj = {
        repairId: this.visitForm.repairId,
        userId: this.visitForm.userId,
        handlerId: this.visitForm.handlerId,
        content: this.visitForm.content,
      }
      let res = await this.repairVisit(obj)
      this.$message.success('提交成功')
      this.refresh()
    },

    async numRefresh(){
       this.statusNum =await this.getStatusNum({type:1,xxid:this.queryParams.xxid})
    }
  },
  async mounted() {
    console.log("netrepair===mounted")
    await this.handleOrder()

    this.statusNum =await this.getStatusNum({
      type:1
    })
    console.log('====',this.statusNum)
    // if(this.getPageParams(this.$options.name))
    //   mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.flex {
  display: flex;
  justify-content: space-around;
}
.status {
  display: flex;
  flex-direction: column;
  text-align: center;
  .name{
    font-size: 20px;
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;;
  }
  .num {
    color: #FF5353;
    font-size: 40px;
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;;
  }
}
/deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
  /deep/.el-drawer__header{ margin-bottom: 0px;}
  .allocationdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
    .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
      .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: #F5F7F9; padding: 20px;
        .area{display: inline-block; margin-right: 16px;
          .con-txt{ font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 20px;}
          .mission { font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px; margin: 10px 0;}
        }
        .nodeny{ margin-top: 20px;
          .no, .cause{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px; }
          .cause{ color: $c-2; margin-left: 10px;}
        }
      }
    }
    .headcon{
      .contit{ color: $c-2; padding: 20px 0; font-size: 18px; font-weight: bold;}
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px;border-radius: 50%; }
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
    .d-bodyFirst { 
      .contit { color: $c-1; padding: 10px 0; line-height: 18px; font-size: 18px; font-weight: bold; }
      .conitem{ 
        .conlable { display: inline-block; line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin: 10px 0; }
        .el-image { display: inline-block; width: 28%; margin-right: 20px; border-radius: 4px; }
        .contxt { display: inline-block; margin-left: 16px; }
      }
      .taskcon { background: #F5F7F9; border-radius: 6px; padding: 20px;margin: 20px 0; }
      .checkMaster { border-radius: 6px; padding: 10px 20px ; background: $c-border; margin-top: 10px; width: 100%;
        .checkTitle { font-size: 16px; font-weight: bold; color: $c-2; }
      }
    }
  }
</style>